.btn-position {
   margin-left: 1rem !important;
}
.Mui-selected{
   background-color: #0203ad !important;
   color:#fff !important;
}
.Mui-SvgIcon-root > .Mui-selected
{
   fill: #fff
}
span > svg {
   fill:#546E7A !important
}
.Mui-selected > .MuiListItemIcon-root > svg {
   fill:#fff !important
}
.MuiListItemIcon-root > svg {
   fill: #546E7A
}
span > span {
   font-size: 0.875rem;
}
.side-navigation-panel-select-option-wrap {
   flex: 1 !important;
}
.side-navigation-panel-select-option-text {
   flex: 1 !important;
}

.title-arrow {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.title-arrow > svg {
   align-items: right;
}
.side-navigation-panel-select-option-selected > span > svg {
   fill:#fff !important
}

.side-navigation-panel-select-option-selected > span > svg:hover{
   fill:#fff !important
}


.side-navigation-panel-select-option-selected > span > span {
   color:#fff !important
}
.side-navigation-panel-select-option-selected > span > span:hover {
   color:#fff !important
}

::-webkit-scrollbar {
   width: 6px;
   height: 10px;
}
::-webkit-scrollbar-track {
   background: #fff;       
}
::-webkit-scrollbar-thumb {
   background-color: rgba(0,0,0,0.1);   
   border-radius: 20px;      
}
.styleScroll {
   /*estilização para firefox*/
   scrollbar-width: thin;
   scrollbar-color: rgba(0,0,0,0.1) #fff;
}